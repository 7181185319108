export interface ScheduleStateModel {
  schedule: ScheduleResponse[];
  loading: LoadingScheduleState;
  error: any | null;
}

export interface ScheduleResponse {
  id?: string;
  trackName?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface ScheduleRequest {
  day: Weekdays
  periods: string[]
  learningId: string
}

export enum LoadingScheduleState {
  loadingList = 'loadingList',
  loadingAddUpdate = 'loadingAddUpdate',
  loadingDelete = 'loadingDelete',
  notLoading = 'notLoading',
}

type Weekdays = 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday';