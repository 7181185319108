export interface FacilitatorStateModel {
    facilitators: FacilitatorModel[];
    loading: LoadingFacilitatorState;
    error: any | null;
}

export interface FacilitatorModel {
    authToken: string | null;
    token: string | null;
    id: string | null,
    email: string | null,
    isOtpEnabled: boolean,
    otpMeans: "App" | "Email" | "None",
    isStaff: boolean,
}

export interface FacilitatorRequest {
    id?: string
    firstName: string
    lastName: string
    email: string
    office: string
    role: string
    gender?: "Female" | "Male"
}

export enum LoadingFacilitatorState {
    loadingList = "loadingList",
    loadingAddUpdate = "loadingAddUpdate",
    loadingDelete = "loadingDelete",
    notLoading = "notLoading",
}