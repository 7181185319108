import {
  LoadingScheduleState,
  ScheduleRequest,
} from '@tsin-core/models/schedule.model';

export class CreateSchedule {
  static readonly type = '[Schedule] Create';
  constructor(public payload: Omit<ScheduleRequest, 'id'>) { }
}

export class GetSchedulesByLearning {
  static readonly type = '[Schedule] Get';
  constructor(public learningId: string) { }
}

export class GetSingleSchedules {
  static readonly type = '[Schedule] Get Single';
}

export class SetLoading {
  static readonly type = '[Schedule] Set Loading';
  constructor(public loading: LoadingScheduleState) { }
}

export class SetError {
  static readonly type = '[Schedule] Set Error';
  constructor(public error: string | null) { }
}
