import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter, withComponentInputBinding, withDebugTracing } from '@angular/router';
import { NgxsStoragePluginModule, StorageOption, withNgxsStoragePlugin } from '@ngxs/storage-plugin';

import { routes } from './app.routes';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxsModule } from '@ngxs/store';
import { AuthState } from '@tsin-core/states/auth.state';
import { CounterState } from '@tsin-core/states/counter.state';
import { environment } from '@tsin-env/environment';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { jwtInterceptor } from '@tsin-core/interceptors/jwt.interceptor';
import { loggingInterceptor } from '@tsin-core/interceptors/logging.interceptor';
import { FacilitatorState } from '@tsin-core/states/facilitator.state';
import { SessionState } from '@tsin-core/states/session.state';
import { CaseState } from '@tsin-core/states/case.state';
import { LearningProgramState } from '@tsin-core/states/learning.state';
import { TrackManagementState } from '@tsin-core/states/track-management.state';
import { ScheduleState } from '@tsin-core/states/schedule.state';
import { OrientationState } from '@tsin-core/states/orientation.state';
import { ApplicationState } from '@tsin-core/states/application.state';
import { UserState } from '@tsin-core/states/user.state';
import { UploadCSVState } from '@tsin-core/states/upload-csv.state';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes, 
      withComponentInputBinding(),
      withDebugTracing()
    ),
    importProvidersFrom(BrowserAnimationsModule),
    provideHttpClient(
      withInterceptors([ jwtInterceptor, loggingInterceptor]),
    ),
    // AppStoreModule)
    importProvidersFrom(
      NgxsModule.forRoot([AuthState, FacilitatorState, LearningProgramState, LearningProgramState, LearningProgramState, OrientationState, UploadCSVState, UserState, UserState, ApplicationState, ApplicationState, SessionState, CaseState, TrackManagementState, ScheduleState], {
      developmentMode: !environment.production
    }),
      NgxsStoragePluginModule.forRoot({ keys: ['authState.auth', 'facilitatorState.facilitators', 'learningState.learningPrograms', 'learningState.selectedLearningProgram',  'learningState.userSelectedPrograms', 'orientationState.orientations', 'uploadCSVState.history', 'userState.users', 'userState.guests', 'applicationState.application', 'applicationState.selectApplication', 'applicationState.isUserHasRegistrations'], storage: StorageOption.LocalStorage }),
      NgxsLoggerPluginModule.forRoot({
        disabled: environment.production
      }),
      NgxsReduxDevtoolsPluginModule.forRoot({
        disabled: environment.production
      }))
  ]
};
