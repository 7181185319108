export const environment = {
  production: true,
  // userBaseUrl: 'https://user-dev.tsin.ca/api/',
  // paymentUrl: 'https://d2spchnag7ram3.cloudfront.net',
  // learningBaseUrl: 'https://learning-dev-be.tsin.ca/api',
  // salt: 'ZC\f?.Kp[jnhXf3-"<f3{/tZS@#~(8on'
  userBaseUrl: 'https://user-prod.tsin.ca/api',
  userMgtUrl: 'https://portal1.tsin.ca/auth/login',
  paymentUrl: 'https://payment-prod.tsin.ca',
  learningBaseUrl: 'https://learn-be.tsin.ca/api',
  salt: 'ZC\f?.Kp[jnhXf3-"<f3{/tZS@#~(8on',
  oldApiUrl: 'https://portal-be.tsin.ca/api/',
  jiraLink: 'https://tsin.atlassian.net/servicedesk/customer/portal/8'
};
