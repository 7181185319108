
export interface CaseStateModel {
  cases:CaseResponse[] ;
  loading: LoadingCaseState;
  error: any | null;
}

export interface CaseResponse {
  id: string
  name: string
  description: string
  isActive: boolean
  sessionId: string
  createdAt: string
  updatedAt: string
}

export interface CaseRequest {
  caseId?: string
  name: string
  sessionId: string
  id?: string
}

export enum LoadingCaseState {
  loadingList = "loadingList",
  loadingAddUpdate = "loadingAddUpdate",
  loadingDelete = "loadingDelete",
  notLoading = "notLoading",
}