import { inject, Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { ReassignLearners } from "@tsin-core/actions/application.action";
import { AddFacilitator, DeleteFacilitator, GetFacilitators, SetError, SetLoading, UpdateFacilitator } from "@tsin-core/actions/facilitator.action";
import { AddLearningFees, AddLearningProgram, DeleteLearningFee, DeleteLearningProgram, GetLearningFee, GetLearningFees, GetLearningProgram, GetLearningPrograms, SetLearningError, SetLearningLoading, SetUserSelectedLearning, UpdateLearningFees, UpdateLearningProgram, UpdateLearningProgramStatus } from "@tsin-core/actions/learning.action";
import { LearningStateModel, LoadingLearningState } from "@tsin-core/models/learning.model";
import { LearningService } from "@tsin-core/services/http/learning.service";
import { UserService } from "@tsin-core/services/http/user.service";
import { catchError, of, tap } from "rxjs";

@State<LearningStateModel>({
    name: 'learningState',
    defaults: {
        loading: LoadingLearningState.loadingList,
        learningPrograms: [],
        selectedLearningProgram: null,
        userSelectedPrograms: [],
        learningFees: [],
        error: null,
    }
})

@Injectable()
export class LearningProgramState {

    userService: UserService = inject(UserService);
    learningService: LearningService = inject(LearningService);

    @Selector()
    static getLearningPrograms(state: LearningStateModel) {
        return state.learningPrograms;
    }

    @Selector()
    static getSelectedLearningProgram(state: LearningStateModel) {
        return state.selectedLearningProgram;
    }


    @Selector()
    static getUserSelectedLearningPrograms(state: LearningStateModel) {
        return state.userSelectedPrograms;
    }

    @Selector()
    static getLearningFees(state: LearningStateModel) {
        return state.learningFees;
    }

    @Selector()
    static getLoading(state: LearningStateModel) {
        return state.loading;
    }

    @Selector()
    static getError(state: LearningStateModel) {
        return state.error;
    }

    @Action(AddLearningProgram)
    addLearningProgram(ctx: StateContext<LearningStateModel>, action: AddLearningProgram) {
        ctx.patchState({ loading: LoadingLearningState.loadingAddUpdate, error: null });
        return this.learningService.addLearning(action.payload).pipe(
            tap((result: any) => {
                const state = ctx.getState();
                console.log(result);
                console.log(state.learningPrograms);
                ctx.patchState({
                    learningPrograms: [...state.learningPrograms, result],
                    loading: LoadingLearningState.notLoading
                });
            }),
            catchError((error) => {
                let errorMessage = error.statusText;
                if (error.error.message && Array.isArray(error.error.message)) {
                    errorMessage = error.error.message.join(', \n\n\r\t');
                } else if (error.error.message) {
                    errorMessage = error.error.message;
                }
                ctx.patchState({ loading: LoadingLearningState.notLoading, error: errorMessage });
                return of(error);
            })
        );
    }

    @Action(UpdateLearningProgram)
    updateLearningProgram(ctx: StateContext<LearningStateModel>, action: UpdateLearningProgram) {
        ctx.patchState({ loading: LoadingLearningState.loadingAddUpdate });
        return this.learningService.updateLearning(action.payload).pipe(
            tap((result: any) => {
                const state = ctx.getState();
                const learningPrograms = [...state.learningPrograms];
                const learningProgramIndex = learningPrograms.findIndex(item => item.id === action.payload.id);
                learningPrograms[learningProgramIndex] = result.data;
                ctx.patchState({ learningPrograms, loading: LoadingLearningState.notLoading });
            }),
            catchError((error) => {
                ctx.patchState({ loading: LoadingLearningState.notLoading, error: error.message });
                return of(error);
            })
        );
    }

    @Action(UpdateLearningProgramStatus)
    updateLearningProgramStatus(ctx: StateContext<LearningStateModel>, action: UpdateLearningProgramStatus) {
        ctx.patchState({ loading: LoadingLearningState.loadingAddUpdate });
        return this.learningService.updateLearningStatus(action.payload).pipe(
            tap((result: any) => {
                console.log('Success!', result.data);
                const state = ctx.getState();
                const learningPrograms = [...state.learningPrograms];
                const learningProgramIndex = learningPrograms.findIndex(item => item.id === action.payload.id);
                learningPrograms[learningProgramIndex] = { ...learningPrograms[learningProgramIndex], ...result.data };
                ctx.patchState({ learningPrograms, loading: LoadingLearningState.notLoading });
            }),
            catchError((error) => {
                ctx.patchState({ loading: LoadingLearningState.notLoading, error: error.message });
                return of(error);
            })
        );
    }

    @Action(DeleteLearningProgram)
    deleteFacilitor(ctx: StateContext<LearningStateModel>, action: DeleteLearningProgram) {
        ctx.patchState({ loading: LoadingLearningState.loadingDelete });
        return this.learningService.deleteLearning(action.id).pipe(
            tap(() => {
                const state = ctx.getState();
                const filteredLearningPrograms = state.learningPrograms.filter(learningProgram => learningProgram.id !== action.id);
                ctx.patchState({ learningPrograms: filteredLearningPrograms, loading: LoadingLearningState.notLoading });
            }),
            catchError((error) => {
                ctx.patchState({ loading: LoadingLearningState.notLoading, error: error.message });
                return of(error);
            })
        );
    }

    @Action(GetLearningPrograms)
    getLearningPrograms(ctx: StateContext<LearningStateModel>, action: GetLearningPrograms) {
        ctx.patchState({ loading: LoadingLearningState.loadingList, error: null });
        return this.learningService.getLearning(action.payload).pipe(
            tap((learningPrograms: any) => {
                ctx.patchState({ learningPrograms: learningPrograms.learnings, loading: LoadingLearningState.notLoading });
            }),
            catchError((error) => {
                ctx.patchState({ loading: LoadingLearningState.notLoading, error: error.message });
                return of(error);
            })
        );
    }


    @Action(GetLearningProgram)
    getLearningProgram(ctx: StateContext<LearningStateModel>, action: GetLearningProgram) {
        ctx.patchState({ loading: LoadingLearningState.loadingList, error: null });
        return this.learningService.getLearningById(action.id).pipe(
            tap((learningProgram: any) => {
                console.log('Single Learning Program', learningProgram);
                ctx.patchState({ selectedLearningProgram: learningProgram, loading: LoadingLearningState.notLoading });
            }),
            catchError((error) => {
                ctx.patchState({ loading: LoadingLearningState.notLoading, error: error.message });
                return of(error);
            })
        );
    }

    @Action(AddLearningFees)
    addLearningFees(ctx: StateContext<LearningStateModel>, action: AddLearningFees) {
        ctx.patchState({ loading: LoadingLearningState.loadingAddUpdate, error: null });
        return this.learningService.setLearningFees(action.payload).pipe(
            tap((result: any) => {
                console.log('Set Fees Success:::', result);
                const state = ctx.getState();
                const learningFees = [...state.learningFees, result];
                // const learningFeesIndex = learningFees.findIndex(item => item.id === action.payload.id);
                // learningFees[learningFeesIndex] = result.data;
                ctx.patchState({ learningFees, loading: LoadingLearningState.notLoading });
            }),
            catchError((error) => {
                ctx.patchState({ loading: LoadingLearningState.notLoading, error: error.error.message ?? error.error.error });
                return of(error);
            })
        );
    }

    @Action(UpdateLearningFees)
    updateLearningFees(ctx: StateContext<LearningStateModel>, action: UpdateLearningFees) {
        ctx.patchState({ loading: LoadingLearningState.loadingAddUpdate, error: null });
        return this.learningService.updateLearningFees(action.payload).pipe(
            tap((result: any) => {
                const state = ctx.getState();
                const learningFees = [...state.learningFees];
                const learningFeesIndex = learningFees.findIndex(item => item.id === action.payload.id);
                learningFees[learningFeesIndex] = result;
                ctx.patchState({ learningFees, loading: LoadingLearningState.notLoading, error: null });
            }),
            catchError((error) => {
                ctx.patchState({ loading: LoadingLearningState.notLoading, error: error.error.message });
                return of(error);
            })
        );
    }

    @Action(GetLearningFees)
    getLearningFees(ctx: StateContext<LearningStateModel>, action: GetLearningFees) {
        ctx.patchState({ loading: LoadingLearningState.loadingList, error: null });
        return this.learningService.getLearningFees(action.payload).pipe(
            tap((learningFees: any) => {
                // console.log('Learning Fees::', learningFees);
                ctx.patchState({ learningFees: learningFees.sort(), loading: LoadingLearningState.notLoading });
            }),
            catchError((error) => {
                ctx.patchState({ loading: LoadingLearningState.notLoading, error: error.message });
                return of(error);
            })
        );
    }


    @Action(GetLearningFee)
    getLearningFee(ctx: StateContext<LearningStateModel>, action: GetLearningFee) {
        ctx.patchState({ loading: LoadingLearningState.loadingList, error: null });
        return this.learningService.getLearningFee(action.payload).pipe(
            tap((learningFees: any) => {
                ctx.patchState({ learningFees: learningFees, loading: LoadingLearningState.notLoading });
            }),
            catchError((error) => {
                ctx.patchState({ loading: LoadingLearningState.notLoading, error: error.message });
                return of(error);
            })
        );
    }


    @Action(DeleteLearningFee)
    deleteLearningFee(ctx: StateContext<LearningStateModel>, action: DeleteLearningFee) {
        ctx.patchState({ loading: LoadingLearningState.loadingList, error: null });
        return this.learningService.deleteLearningFee(action.payload).pipe(
            tap(() => {
                const state = ctx.getState();
                const learningFees = state.learningFees.filter(
                    (learningFee) => learningFee.id !== action.payload
                );
                // console.log('Delete Learning Fees::', learningFees);
                ctx.patchState({
                    learningFees: learningFees,
                    loading: LoadingLearningState.notLoading,
                });
            }),
            catchError((error) => {
                ctx.patchState({ loading: LoadingLearningState.notLoading, error: error.error.message });
                return of(error);
            })
        );
    }

    @Action(SetLearningLoading)
    setLoading(ctx: StateContext<LearningStateModel>, action: SetLearningLoading) {
        ctx.patchState({ loading: action.loading });
    }

    @Action(SetUserSelectedLearning)
    setUserSelectedLearning(ctx: StateContext<LearningStateModel>, action: SetUserSelectedLearning) {
        ctx.patchState({ userSelectedPrograms: action.payload });
    }

    @Action(SetLearningError)
    setError(ctx: StateContext<LearningStateModel>, action: SetLearningError) {
        ctx.patchState({ error: action.error });
    }
}