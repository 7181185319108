import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@tsin-env/environment';

const SCHEDULE_URL = `${environment.learningBaseUrl}/schedules`;

@Injectable({
  providedIn: 'root',
})

export class ScheduleService {

  http: HttpClient = inject(HttpClient);

  createSchedule(payload: any) {
    return this.http.post(SCHEDULE_URL, payload);
  }

  getSchedule() {
    return this.http.get(SCHEDULE_URL);
  }

  createWeeklySchedule(payload: any) {
    return this.http.post(SCHEDULE_URL + `/weekly`, payload);
  }

  getWeeklySchedule() {
    return this.http.get(SCHEDULE_URL + `/weekly`);
  }

  getWeeklyScheduleByLearning(learningId: string) {
    return this.http.get(SCHEDULE_URL + `/weekly/learning/${learningId}`);
  }

  updateMemberGroup(groupName: string, user: any) {
    return this.http.put(SCHEDULE_URL + `/members`, { ...user, group: groupName, });
  }

  updateLeaderGroup(user: any) {
    return this.http.put(SCHEDULE_URL + `/leaders`, user);
  }

  updateWeeklySchedule(payload: any) {
    return this.http.put(SCHEDULE_URL + `/weekly`, payload);
  }

  getSingleWeeklySchedule(id: any) {
    return this.http.get(SCHEDULE_URL + `/weekly/single/${id}`);
  }

  deleteSingleWeeklySchedule(id: any) {
    return this.http.delete(SCHEDULE_URL + `/weekly/single/${id}`);
  }

  addWeeklySchedule(payload: any) {
    return this.http.post(SCHEDULE_URL + `/weekly/add-schedule`, payload);
  }

  removeWeeklySchedule(payload: any) {
    return this.http.delete(SCHEDULE_URL + `/weekly/remove-schedule`, payload);
  }

  generateTracks(payload: any) {
    return this.http.post(SCHEDULE_URL + `/generate-tracks`, payload);
  }

  getSingleSchedule(id: any) {
    return this.http.get(SCHEDULE_URL + `/single/${id}`);
  }

  deleteSingleSchedule(id: any) {
    return this.http.get(SCHEDULE_URL + `/single/${id}`);
  }

  getLeaders(learningId: any) {
    return this.http.get(SCHEDULE_URL + `/leaders/${learningId}`);
  }
}
