import { inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  CreateSchedule,
  GetSchedulesByLearning,
  SetError,
  SetLoading,
} from '@tsin-core/actions/schedule.action';
import {
  LoadingScheduleState,
  ScheduleStateModel,
} from '@tsin-core/models/schedule.model';
import { ScheduleService } from '@tsin-core/services/http/schedule.service';
import { catchError, of, tap } from 'rxjs';

@State<ScheduleStateModel>({
  name: 'scheduleState',
  defaults: {
    loading: LoadingScheduleState.loadingList,
    schedule: [],
    error: null,
  },
})
@Injectable()
export class ScheduleState {
  scheduleService = inject(ScheduleService);

  @Selector()
  static getTrackManagement(state: ScheduleStateModel) {
    return state.schedule;
  }

  @Selector()
  static getLoading(state: ScheduleStateModel) {
    return state.loading;
  }

  @Selector()
  static getError(state: ScheduleStateModel) {
    return state.error;
  }

  @Action(GetSchedulesByLearning)
  getSchedule(ctx: StateContext<ScheduleStateModel>, action: GetSchedulesByLearning) {
    ctx.patchState({ loading: LoadingScheduleState.loadingList });

    return this.scheduleService.getSchedule().pipe(
      tap((tracks: any) => {
        ctx.patchState({
          schedule: tracks.data,
          loading: LoadingScheduleState.notLoading,
        });
      }),
      catchError((error) => {
        ctx.patchState({
          loading: LoadingScheduleState.notLoading,
          error: error.message,
        });
        return of(error);
      })
    );
  }

  @Action(CreateSchedule)
  createSchedule(
    ctx: StateContext<ScheduleStateModel>,
    action: CreateSchedule
  ) {
    ctx.patchState({ loading: LoadingScheduleState.loadingAddUpdate });

    return this.scheduleService.createSchedule(action.payload).pipe(
      tap((result: any) => {
        const state = ctx.getState();
        ctx.patchState({
          schedule: [...state.schedule, result],
          loading: LoadingScheduleState.notLoading,
        });
      }),
      catchError((error) => {
        ctx.patchState({
          loading: LoadingScheduleState.notLoading,
          error: error.message,
        });
        return of(error);
      })
    );
  }

  @Action(SetLoading)
  setLoading(ctx: StateContext<ScheduleStateModel>, action: SetLoading) {
    ctx.patchState({ loading: action.loading });
  }

  @Action(SetError)
  setError(ctx: StateContext<ScheduleStateModel>, action: SetError) {
    ctx.patchState({ error: action.error });
  }
}
